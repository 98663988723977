<template>
  <div style="">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <InputFieldComponent
            label="Business Name"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.business_name.$model"
            :message="!$v.form.business_name.required && $v.form.business_name.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-100 mt-5">
        <label class="mb-2 text-uppercase font-bold block">ABN/ACN Number</label>
        <AccountNumberComponent :data="form.account_number" ref="account_number"/>
      </div>
      <div class="md-layout-item md-size-50 mt-5">
        <InputFieldComponent
            label="Business Email Address"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.business_email.$model"
            :message="!$v.form.business_email.required && $v.form.business_email.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-50 mt-5">
        <InputFieldComponent
            label="Business Contact Number"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.business_contact_number.$model"
            :message="!$v.form.business_contact_number.required && $v.form.business_contact_number.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-50 mt-5">
        <InputFieldComponent
            label="Website"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.business_website.$model"
            :message="!$v.form.business_website.required && $v.form.business_website.$dirty ? 'Field is required' : null"
        />
      </div>
    </div>
    <AddressComponent :data="address"  ref="address" />

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>

import {
  Button,
  InputFieldComponent,
  AccountNumberComponent,
  AddressComponent
} from "@/components"
import { required } from "vuelidate/lib/validators";
import { email } from "vuelidate/lib/validators";
export default {

  name: "BasicInformation",

  components: {
    Button,
    InputFieldComponent,
    AccountNumberComponent,
    AddressComponent
  },

  data() {
    return {
      loading: false,

      address: {},
      form: {
        active: 0,
        business_name: "",
        business_account_number: "",
        account_number: [],
        business_email: '',
        business_contact_number: '',
        business_website: '',
        business_address_1: "",
        business_address_2: '',
        business_full_address:'',
        business_postal_code_id:'',
        business_country_id: "",
        business_state: '', 
        business_city: '', 
        country: '',
        your_country: '',
        your_country_id: '',
        postal_code: '',
        type: 'COACHING_CENTER',
      }
    }
  },

  methods: {
 
    submit() {
      this.form.business_state = this.$refs.address.$data.form.state;
      this.form.business_city = this.$refs.address.$data.form.city;
      this.form.business_address_1 = this.$refs.address.$data.form.address_1;
      this.form.business_address_2 = this.$refs.address.$data.form.address_2;
      this.form.business_postal_code_id = this.$refs.address.$data.form.postal_code_id;
      this.form.business_country_id = this.$refs.address.$data.form.country_id;
      this.form.your_country = this.$refs.address.$data.form.your_country;
      this.form.your_country_id = this.$refs.address.$data.form.your_country_id;
      this.form.country = this.$refs.address.$data.form.country;

      this.form.account_number = this.$refs.account_number.$data.account_number;
      let ac_number = this.form.account_number.join('');
  
      this.$v.$touch();
      if(this.$v.$invalid) return;

      let formData = this.getLocal('coaching-center-onboard');
      this.form.business_account_number = ac_number;

      formData.organization = {
        ...formData.organization,
        ...this.form
      };
      formData.organization.business_full_address = this.form.business_address_1 +' '+ this.form.business_address_1;
     
      this.setLocal('coaching-center-onboard', formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'coachingCenterPackage'});
    },

    clearAll(){
      this.$emit("clear-all", "");
    }
  },

  created() {
   
      let existData = this.getLocal('coaching-center-onboard');

      if (existData && existData.organization && existData.organization.business_name) this.form.business_name = existData.organization.business_name;
      if (existData && existData.organization && existData.organization.business_email) this.form.business_email = existData.organization.business_email;
      if (existData && existData.organization && existData.organization.business_contact_number) this.form.business_contact_number = existData.organization.business_contact_number;
      if (existData && existData.organization && existData.organization.business_website) this.form.business_website = existData.organization.business_website;
      if (existData && existData.organization && existData.organization.business_address_1) this.form.business_address_1 = existData.organization.business_address_1;
      if (existData && existData.organization && existData.organization.business_country_id) this.form.business_country_id = existData.organization.business_country_id;
      if (existData && existData.organization && existData.organization.business_state) this.form.business_state = existData.organization.business_state;
      if (existData && existData.organization && existData.organization.business_city) this.form.business_city = existData.organization.business_city;
      if (existData && existData.organization && existData.organization.business_postal_code_id) this.form.business_postal_code_id = existData.organization.business_postal_code_id;
      if (existData && existData.organization && existData.organization.business_account_number) this.form.business_account_number = existData.organization.business_account_number;
      if (existData && existData.organization && existData.organization.account_number) this.form.account_number = existData.organization.account_number;
      
      this.address.address_1 = this.form.business_address_1;
      this.address.address_2 = this.form.business_address_2;
      this.address.state = this.form.business_state;
      this.address.city = this.form.business_city;
      this.address.postal_code_id = this.form.business_postal_code_id;
      this.address.your_country = this.form.your_country;
      this.address.country_id = this.form.business_country_id;
      this.address.your_country_id = this.form.your_country_id;
      this.address.country = this.form.country;
  },

  validations: {
    form: {
      business_name: {required},
      business_email: {required, email},
      business_contact_number: {required},
      business_website: {required}
    }
  }
}
</script>

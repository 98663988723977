<template>
  <div style="">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
     
        <InputFieldComponent
            label="Full Address"
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.address_1.$model"
            :message="!$v.form.address_1.required && $v.form.address_1.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-100 mt-4">
        <InputFieldComponent
            :requiredLabel="true"
            label-class="text-sm"
            v-model.trim="$v.form.address_2.$model"
            :message="!$v.form.address_2.required && $v.form.address_2.$dirty ? 'Field is required' : null"
        />
      </div>
      <div v-if="!orderCountries" class="md-layout-item md-size-50  mt-5">
        <SelectComponent
            :items="postalCodes"
            label="Postal Code"
            class="w-full mb-2"
            return-type="object"
            @getObject="setPostalCode"
        />
      </div>
      <div v-if="orderCountries" class="md-layout-item md-size-50 mt-5">
        <InputFieldComponent
            :requiredLabel="true"
            label="Postal code"
            label-class="text-sm"
            v-model.trim="$v.form.postal_code_id.$model"
            :message="!$v.form.postal_code_id.required && $v.form.postal_code_id.$dirty ? 'Field is required' : null"
            />
      </div>
    </div>
    <div class="md-layout mt-5">
      <div class="md-layout-item md-size-50 ">
        <InputFieldComponent
            :requiredLabel="true"
            label="State"
            label-class="text-sm"
            v-model.trim="$v.form.state.$model"
            :message="!$v.form.state.required && $v.form.state.$dirty ? 'Field is required' : null"
            />
      </div>
      <div class="md-layout-item md-size-50">
   
        <InputFieldComponent
            :requiredLabel="true"
            label="City"
            label-class="text-sm"
            v-model.trim="$v.form.city.$model"
            :message="!$v.form.city.required && $v.form.city.$dirty ? 'Field is required' : null"
            />
      </div>

      <div class="md-layout-item md-size-100 mt-5">
          <SelectComponent
            :items="countries"
            label="Country"
            :requiredLabel="true"
            class="w-full mb-2"
            v-model="$v.form.country_id.$model"
            :message="!$v.form.country_id.required && $v.form.country_id.$dirty ? 'Field is required' : null"
            @input="getCountryInputChange"
            @getObject="getCountryId"
        />
      </div>
      <div v-if="orderCountries" class="md-layout-item md-size-50 mt-5">
        <SelectComponent
            :items="otherCountries"
            label="Your Country"
            class="w-full mb-2"
            @input="getOtherCountryInputChange"
            v-model.trim="form.your_country_id"
            />
      </div>

    </div>

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>

import {
  Button,
  InputFieldComponent,
  SelectComponent
} from "@/components"
import { required } from "vuelidate/lib/validators";
import {mapActions, mapMutations} from 'vuex';
export default {
  name: "BasicInformation",

  components: {
    Button,
    InputFieldComponent,
    SelectComponent
  },

  data() {
    return {
      loading: false,
      orderCountries: false,
      countries: [],
      postalCodes: [],
      otherCountries: [],
      form: {
        address_1: "",
        address_2: '',
        postal_code_id:'',
        country_id: "",
        state: '',
        city: '', 
        country: '',
        your_country: '',
        your_country_id: '',
        postal_code: '',
      }
    }
  },

  methods: {
    ...mapMutations({
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actCountryList: "registration/actCountryList",
      actPostalCodesByCountry: "registration/actPostalCodesByCountry",
    }),
    setPostalCode(postalCode) {
      this.form.postal_code_id = postalCode.id;
      this.form.postal_code = postalCode.name;
      this.form.state = postalCode.state;
      this.form.city = postalCode.city;
    },

    getCountryName() {
      let country =  this.countries.filter(country => {
        if(country.id === this.form.country_id) return country;
      });
      if(typeof country !== "undefined" && country) return country[0];
    },
    getCountryId(countryId) {
      this.getPostalCodes(countryId);
    },

    getPostalCodes(countryId) {

      this.actPostalCodesByCountry(countryId)
          .then(({items}) => {
            this.postalCodes = items;
            var postalCode = this.postalCodes.slice(0, 1).shift();
            if(typeof postalCode !== "undefined" && postalCode){
              this.form.postal_code_id = postalCode.id;
              this.form.postal_code = postalCode.name;
              this.form.state = postalCode.state;
              this.form.city = postalCode.city;
            }else{
              this.form.postal_code_id = "";
              this.form.postal_code = "";
              this.form.state = "";
              this.form.city = "";
            }
          });
    },

    getCountryInputChange(countryId) {
     
      if (countryId == 'others') {
        this.form.country = countryId;
        this.form.country_id = countryId;
        this.form.city = '';
        this.form.state = '';
        this.form.postal_code_id = '';
        this.orderCountries = true;

        //load first country details
        let otherCountry = this.otherCountries.slice(0, 1).shift();
        if(typeof otherCountry !== "undefined" && otherCountry) {
          this.form.your_country = otherCountry.name;
          this.form.country_id = otherCountry.id;
          this.getPostalCodes(otherCountry.id);
        }
      } else {
        this.getPostalCodes(countryId);
        this.orderCountries = false;
        let countryDerails = this.getCountryName();
        this.form.country_id = countryDerails.id;
        this.form.country = countryDerails.name;
      }
    },

    getOtherCountryName() {
      let otherCountry =  this.otherCountries.filter(otherCountry => {
        if(otherCountry.id === this.form.your_country) return otherCountry;
      });
      if(typeof otherCountry !== "undefined" && otherCountry) return otherCountry[0];
    },
    
    getOtherCountryInputChange(otherCountryId){
      this.form.your_country = otherCountryId;
      this.getPostalCodes(otherCountryId);
      let otherCountryDerails = this.getOtherCountryName();
      this.form.your_country = otherCountryDerails.name;
    },
 
    submit() {

      this.$v.$touch();
      if(this.$v.$invalid) return;

      let formData = this.getLocal('coaching-center-onboard');

      
      formData.user.user_profile = {
        ...formData.user.user_profile,
        ...this.form,
        contact_number: formData.user.contact_number
      };
      formData.user.user_profile.address = this.form.address_1 +' '+ this.form.address_2;
      this.setLocal('coaching-center-onboard', formData);      
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'coachingCenterBusinessInfo'});
    },

    clearAll(){
      this.$emit("clear-all", "");
    }
  },

  validations: {
    form: {
      address_1: {required},
      address_2: {required},
      country_id: {required},
      state: {required},
      city: {required},
      postal_code_id: {required},
    }
  },

  created() {

    this.actCountryList().then(({main, others}) => {
      this.countries = main;
      this.otherCountries = others;
      this.countries.push({
        name: 'OTHER',
        id: 'others'
      })

    
      //load first country details
      let firstCountry = this.countries.slice(0, 1).shift();
      if(typeof firstCountry !== "undefined" && firstCountry){
        this.form.country = firstCountry.name;
        this.form.country_id = firstCountry.id;
        this.getPostalCodes(firstCountry.id);
      }

      let existData = this.getLocal('coaching-center-onboard');
      if (existData && existData.user && existData.user.user_profile) this.form = existData.user.user_profile
    });
  },
}
</script>
